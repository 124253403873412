import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { incrementalDateValidation } from '../../utils/incrementalDateValidation'
import { Wrapper, Container, Title, HeaderRow, Form, FormGroup, FormRow } from './style'
import { FormError, FormLabel } from 'Rentlio/components/UI/form'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { Input } from 'Rentlio/components/UI/input'
import InputWithIcon from 'Rentlio/components/UI/InputWithIcon'
import Email from 'Rentlio/components/Icons/email'
import Select from 'Rentlio/components/UI/Select'
import { CountriesEnum } from 'Rentlio/utils/countriesEnum'
import { GenderEnum } from 'Rentlio/utils/genderEnum'
import Phone from 'Rentlio/components/Icons/phone'
import Calendar from 'Rentlio/components/Icons/calendar'
import { getValidationErrors } from 'Rentlio/helper/validator'
import { TravelDocumentTypesEnum } from 'Rentlio/utils/travelDocumentTypesEnum'

const ID_CARD = 1
const PASSPORT = 2

const Guest = ({ guest, index, updateGuestField, errors, formDisabled, locale }) => {
  const isEnglish = window.initialBookingEngineData.configuration.locale === locale
  const { translate } = useContext(LanguageContext)
  const [documentTypesId, setDocumentTypesId] = useState(null)
  const documentTypes = [
    {
      label: translate('Personal ID card'),
      value: ID_CARD
    },
    {
      label: translate('Passport'),
      value: PASSPORT
    }
  ]
  const getValidationError = key => getValidationErrors(errors, key)
  const { city, countryOfBirthId, dateOfBirth, documentNumber, email, genderId, name, contactNumber } = guest

  useEffect(() => {
    const croatiaId = 53

    if (guest.countryOfBirthId === croatiaId) {
      if (documentTypesId === PASSPORT) {
        updateGuestField(index, 'travelDocumentTypesId', TravelDocumentTypesEnum.PersonalPassportCroatian)
      } else if (documentTypesId === ID_CARD) {
        updateGuestField(index, 'travelDocumentTypesId', TravelDocumentTypesEnum.PersonalIDCardCroatian)
      }
    }
    if (guest.countryOfBirthId !== croatiaId) {
      if (documentTypesId === PASSPORT) {
        updateGuestField(index, 'travelDocumentTypesId', TravelDocumentTypesEnum.PersonalPassportForeign)
      } else if (documentTypesId === ID_CARD) {
        updateGuestField(index, 'travelDocumentTypesId', TravelDocumentTypesEnum.PersonalIDCardForeign)
      }
    }

    if (documentTypesId === null) {
      updateGuestField(index, 'travelDocumentTypesId', null)
    }
    if (guest.countryOfBirthId === null) {
      updateGuestField(index, 'countryOfBirthId', null)
    }
  }, [guest.countryOfBirthId, documentTypesId])

  useEffect(() => {
    const travelDocumentTypesId = guest.travelDocumentTypesId

    if (
      travelDocumentTypesId === TravelDocumentTypesEnum.PersonalPassportCroatian ||
      travelDocumentTypesId === TravelDocumentTypesEnum.PersonalPassportForeign
    ) {
      setDocumentTypesId(PASSPORT)
    } else if (
      travelDocumentTypesId === TravelDocumentTypesEnum.PersonalIDCardCroatian ||
      travelDocumentTypesId === TravelDocumentTypesEnum.PersonalIDCardForeign
    ) {
      setDocumentTypesId(ID_CARD)
    }
  }, [])

  const handleInputChange = (fieldName, value) => {
    updateGuestField(index, fieldName, value)
  }

  const ontravelDocumentsTypeChange = e => {
    setDocumentTypesId(e.value)
  }

  const onDateOfbirthChange = value => {
    const formatedDate = incrementalDateValidation(value, dateOfBirth)
    updateGuestField(index, 'dateOfBirth', formatedDate)
  }

  const getGenderOptions = () => {
    const genderOptions = [{ value: null, label: translate('-- select gender --') }, ...GenderEnum]
    return genderOptions
  }

  const getDocumentTypesOptions = () => {
    const documentTypeOptions = [{ value: null, label: translate('-- select document type --') }, ...documentTypes]
    return documentTypeOptions
  }

  const getCountryOfBirthOptions = () => {
    const countryOfBirthOptions = [{ value: null, label: translate('-- select country of birth --') }, ...CountriesEnum]
    return countryOfBirthOptions
  }

  return (
    <Container>
      <Wrapper>
        <HeaderRow>
          <Title>{guest.isPrimary ? translate('Primary guest') : translate('Guest') + ' ' + (index + 1)}</Title>
        </HeaderRow>

        <Form>
          <FormRow marginBottom={20}>
            <FormGroup fullWidth={true} isEnglish={isEnglish}>
              <FormLabel>
                <label htmlFor='firstName'>{translate('Name and surname')}</label>
              </FormLabel>
              <Input
                id='name'
                placeholder={formDisabled ? '' : translate('Enter name and surname')}
                value={name || ''}
                onChange={e => handleInputChange('name', e.target.value)}
                disabled={formDisabled}
              />
              <FormError>{getValidationError('name').join(', ')}</FormError>
            </FormGroup>
            <FormGroup fullWidth={true} isEnglish={isEnglish}>
              <FormLabel>
                <label htmlFor='dateOfBirth'>{translate('Date of birth')}</label>
              </FormLabel>
              <InputWithIcon
                id='dateOfBirth'
                placeholder={formDisabled ? '' : translate('Enter date (DD.MM.YYYY)')}
                value={dateOfBirth || ''}
                onChange={e => onDateOfbirthChange(e.target.value)}
                icon={<Calendar />}
                disabled={formDisabled}
              />
              <FormError>{getValidationError('dateOfBirth').join(', ')}</FormError>
            </FormGroup>
            <FormGroup fullWidth={true} isEnglish={isEnglish}>
              <FormLabel>
                <label htmlFor='genderId'>{translate('Gender')}</label>
              </FormLabel>
              <Select
                id='genderId'
                placeholder={formDisabled ? '' : '-- select gender --'}
                options={getGenderOptions()}
                selected={genderId}
                selectOption={e => handleInputChange('genderId', e.value)}
                disabled={formDisabled}
              />
              <FormError>{getValidationError('genderId').join(', ')}</FormError>
            </FormGroup>

            <FormGroup fullWidth={true} isEnglish={isEnglish}>
              <FormLabel>
                <label htmlFor='travelDocumentTypesId'>{translate('Type of document')}</label>
              </FormLabel>
              <Select
                id='travelDocumentTypesId'
                placeholder={formDisabled ? '' : '-- select document type --'}
                options={getDocumentTypesOptions()}
                selected={documentTypesId || null}
                selectOption={e => ontravelDocumentsTypeChange(e)}
                disabled={formDisabled}
              />
              <FormError>{getValidationError('travelDocumentTypesId').join(', ')}</FormError>
            </FormGroup>
            <FormGroup fullWidth={true} isEnglish={isEnglish}>
              <FormLabel>
                <label htmlFor='documentNumber'>{translate('Document number')}</label>
              </FormLabel>
              <Input
                id='documentNumber'
                placeholder={formDisabled ? '' : translate('Enter document number')}
                value={documentNumber || ''}
                onChange={e => handleInputChange('documentNumber', e.target.value)}
                disabled={formDisabled}
              />
              <FormError>{getValidationError('documentNumber').join(', ')}</FormError>
            </FormGroup>
            <FormGroup fullWidth={true} isEnglish={isEnglish}>
              <FormLabel>
                <label htmlFor='city'>{translate('Place of residence')}</label>
              </FormLabel>
              <Input
                id='city'
                placeholder={formDisabled ? '' : translate('Enter place of residence')}
                value={city || ''}
                onChange={e => handleInputChange('city', e.target.value)}
                disabled={formDisabled}
              />
              <FormError>{getValidationError('city').join(', ')}</FormError>
            </FormGroup>

            <FormGroup fullWidth={true} isEnglish={isEnglish}>
              <FormLabel>
                <label htmlFor='countryOfBirthId'>{translate('Country of birth')}</label>
              </FormLabel>
              <Select
                id='countryOfBirthId'
                placeholder={formDisabled ? '' : '-- select country of birth --'}
                options={getCountryOfBirthOptions()}
                selected={countryOfBirthId || null}
                selectOption={e => {
                  handleInputChange('countryOfBirthId', e.value)
                }}
                enableSearch={!formDisabled}
                disabled={formDisabled}
              />
              <FormError>{getValidationError('countryOfBirthId').join(', ')}</FormError>
            </FormGroup>
            <FormGroup fullWidth={true} isEnglish={isEnglish}>
              <FormLabel>
                <label htmlFor='email'>{translate('E-mail')}</label>
              </FormLabel>
              <InputWithIcon
                idForInput='email'
                placeholder={formDisabled ? '' : translate('Enter your e-mail')}
                icon={<Email />}
                value={email || ''}
                onChange={e => {
                  handleInputChange('email', e.target.value)
                }}
                disabled={formDisabled}
              />
              <FormError>{getValidationError('email')}</FormError>
            </FormGroup>
            <FormGroup fullWidth={true} isEnglish={isEnglish}>
              <FormLabel>
                <label htmlFor='contactNumber'>{translate('Phone')}</label>
              </FormLabel>
              <InputWithIcon
                id='phone'
                placeholder={formDisabled ? '' : translate('Enter telephone number')}
                value={contactNumber || ''}
                onChange={e => handleInputChange('contactNumber', e.target.value)}
                icon={<Phone />}
                disabled={formDisabled}
              />
            </FormGroup>
          </FormRow>
        </Form>
      </Wrapper>
    </Container>
  )
}

Guest.propTypes = {
  guest: PropTypes.object,
  isPrimary: PropTypes.bool,
  index: PropTypes.number,
  updateGuestField: PropTypes.func,
  errors: PropTypes.object,
  formDisabled: PropTypes.bool,
  locale: PropTypes.string
}

export default Guest
