import moment from 'moment'

const getAvailabilityForYear = (avail, year) => avail[year] || {}

/**
 * @param unitTypeId int
 * @param availability object
 * @param startDate moment()
 * @param endDate moment()
 * @param now moment() - Its used to mock current time during testing
 */
export function getSuggestedDateForBefore(unitTypeId, availability, startDate, endDate, now) {
  if (typeof now === 'undefined') {
    now = moment()
  }

  const suggestedDates = {
    beforeStart: null,
    beforeEnd: null
  }

  if (!startDate || !endDate) {
    return suggestedDates
  }

  // We are adding  +1 since moment uses 0 based notation for months, and we use 1 based notation
  const startDateMonth = startDate.month() + 1
  const startDateYear = startDate.year()
  const previousMonthDateObject = startDate.clone().set('month', startDate.month() - 1)
  const previousYear = previousMonthDateObject.year()
  // We are adding + 1 since moment uses 0 based notation for months, and we use 1 based notation
  const previousMonth = previousMonthDateObject.month() + 1
  // We are adding + 1 since moment uses 0 based notation for months, and we use 1 based notation
  const difference = endDate.diff(startDate, 'days')

  const unitTypeAvailability = availability[unitTypeId]
  if (typeof unitTypeAvailability === 'undefined') {
    return suggestedDates
  }

  const selectedMonthDays = getAvailabilityForYear(unitTypeAvailability, startDateYear)[startDateMonth] || {}
  const previousMonthDays = getAvailabilityForYear(unitTypeAvailability, previousYear)[previousMonth] || {}

  let days = []

  const selectedMonthParsedDays = Object.entries(selectedMonthDays).map(item => ({
    year: startDateYear,
    month: startDateMonth,
    day: parseInt(item[0]),
    availability: item[1]
  }))

  const previousMonthParsedDays = Object.entries(previousMonthDays).map(item => ({
    year: previousYear,
    month: previousMonth,
    day: parseInt(item[0]),
    availability: item[1]
  }))

  days = days.concat(previousMonthParsedDays)
  days = days.concat(selectedMonthParsedDays)

  // tu filtriramo datume koji su između danas i start date + 1 dan (jer je zadnji dostupni departure date = start date)
  days = days.filter(item =>
    moment()
      .year(item.year)
      .month(item.month - 1)
      .date(item.day)
      .isBetween(now, startDate.clone().add(1, 'days'), 'day', '[]')
  )

  let beforeStartDate = null
  let beforeEndDate = null
  let daysInRange = null

  for (let i = days.length - 1; i >= 0; i--) {
    // ako nemamo krajnji datum a imamo nešto što je available, postavi za krajnji datum
    if (!beforeEndDate || days[i].availability === 0) {
      beforeEndDate = days[i]
      daysInRange = 1
      continue
    }

    // Ako dođemo do unavailable, onda uzmi dan prije toga jer mora biti available i ako start i end nisu isti
    if (days[i].availability === 0 && beforeEndDate !== days[i + 1]) {
      beforeStartDate = days[i + 1]
      break
    }

    if (daysInRange !== null && days[i].availability > 0) {
      daysInRange++

      if (daysInRange > difference) {
        beforeStartDate = days[i]
        break
      }
    }
  }

  if (beforeStartDate !== null && beforeEndDate !== null) {
    return {
      beforeStart: moment()
        .year(beforeStartDate.year)
        .month(beforeStartDate.month - 1)
        .date(beforeStartDate.day),
      beforeEnd: moment()
        .year(beforeEndDate.year)
        .month(beforeEndDate.month - 1)
        .date(beforeEndDate.day)
    }
  }

  return suggestedDates
}

/**
 * @param unitTypeId int
 * @param availability object
 * @param startDate moment()
 * @param endDate moment()
 */
export function getSuggestedDateForAfter(unitTypeId, availability, startDate, endDate) {
  const suggestedDates = {
    afterStart: null,
    afterEnd: null
  }

  if (!startDate || !endDate) {
    return suggestedDates
  }

  // We are adding  +1 since moment uses 0 based notation for months, and we use 1 based notation
  const startDateMonth = startDate.month() + 1
  const startDateYear = startDate.year()
  const nextMonthDateObject = startDate.clone().month(startDate.month() + 1)
  const nextYear = nextMonthDateObject.year()
  // We are adding  +1 since moment uses 0 based notation for months, and we use 1 based notation
  const nextMonth = nextMonthDateObject.month() + 1
  const difference = endDate.diff(startDate, 'days')

  const unitTypeAvailability = availability[unitTypeId]
  if (typeof unitTypeAvailability === 'undefined') {
    return suggestedDates
  }

  const selectedMonthDays = getAvailabilityForYear(unitTypeAvailability, startDateYear)[startDateMonth] || {}
  const nextMonthDays = getAvailabilityForYear(unitTypeAvailability, nextYear)[nextMonth] || {}

  let days = []

  const selectedMonthParsedDays = Object.entries(selectedMonthDays).map(item => ({
    year: startDateYear,
    month: startDateMonth,
    day: parseInt(item[0]),
    availability: item[1]
  }))

  const nextMonthParsedDays = Object.entries(nextMonthDays).map(item => ({
    year: nextYear,
    month: nextMonth,
    day: parseInt(item[0]),
    availability: item[1]
  }))

  days = days.concat(selectedMonthParsedDays)
  days = days.concat(nextMonthParsedDays)

  // tu filtriramo datume koji su ili danas ili u budućnosti
  days = days.filter(item =>
    moment()
      .year(item.year)
      .month(item.month - 1)
      .date(item.day)
      .isAfter(startDate)
  )

  let afterStartDate = null
  let afterEndDate = null
  let daysInRange = null

  for (let i = 0; i < days.length; i++) {
    if (!afterStartDate && days[i].availability > 0) {
      afterStartDate = days[i]
      daysInRange = 1
      continue
    }

    if (days[i].availability > 0) {
      daysInRange++
    }

    // Ako dođemo do unavailable, onda uzmi dan prije toga jer mora biti available i ako start i end nisu isti
    if (daysInRange > difference || (days[i].availability === 0 && daysInRange === difference)) {
      afterEndDate = days[i]
      break
    }

    if (days[i].availability === 0 && daysInRange <= difference) {
      afterStartDate = null
      daysInRange = null
      continue
    }
  }

  if (afterStartDate !== null && afterEndDate !== null) {
    return {
      afterStart: moment()
        .year(afterStartDate.year)
        .set('date', afterStartDate.day)
        .set('month', afterStartDate.month - 1),
      afterEnd: moment()
        .year(afterEndDate.year)
        .set('date', afterEndDate.day)
        .set('month', afterEndDate.month - 1)
    }
  }

  return suggestedDates
}
