import PropTypes from 'prop-types'
import React from 'react'
import PackageDeal from './PackageDeal'
import SingleRatePlan from 'Rentlio/screen/accomodation/component/RatePlans/SingleRatePlan'
import { RatePlansWrapper } from 'Rentlio/screen/accomodation/component/RatePlans/style'

class RatePlans extends React.PureComponent {
  isRateAvailable = (basket, ratePlanId, unitTypeId) => {
    const { unitTypes } = this.props
    if (unitTypeId === undefined || ratePlanId === undefined) {
      return false
    }
    const selectedUnitType = unitTypes.find(unitType => unitType.unitTypeId === unitTypeId)
    if (!selectedUnitType) {
      return false
    }
    const alreadyAddedRoomsForUnitType = basket.filter(item => item.unitTypeId === unitTypeId)
    return selectedUnitType.availableUnits - alreadyAddedRoomsForUnitType.length > 0
  }

  render() {
    const {
      rates,
      addItemToBasket,
      removeItemFromBasket,
      wrapperId,
      unitTypeCurrency,
      arrivalDate,
      adults,
      childrenAges,
      onOccupancyChange,
      unitTypeOccupancy,
      basket,
      openPackageDealModal
    } = this.props

    return (
      <RatePlansWrapper id={wrapperId}>
        {rates.map((rate, i) => {
          return rate.isPackageDeal ? (
            <PackageDeal
              ratePlan={rate}
              key={i}
              addItemToBasket={addItemToBasket}
              removeItemFromBasket={removeItemFromBasket}
              unitTypeCurrency={unitTypeCurrency}
              arrivalDate={arrivalDate}
              adults={adults}
              childrenAges={childrenAges}
              onOccupancyChange={onOccupancyChange}
              unitTypeOccupancy={unitTypeOccupancy}
              isAvailable={this.isRateAvailable(basket, rate.ratePlanId, rate.unitTypeId)}
              openPackageDealModal={openPackageDealModal}
            />
          ) : (
            <SingleRatePlan
              ratePlan={rate}
              key={i}
              addItemToBasket={addItemToBasket}
              removeItemFromBasket={removeItemFromBasket}
              unitTypeCurrency={unitTypeCurrency}
              arrivalDate={arrivalDate}
              adults={adults}
              childrenAges={childrenAges}
              onOccupancyChange={onOccupancyChange}
              unitTypeOccupancy={unitTypeOccupancy}
              isAvailable={this.isRateAvailable(basket, rate.ratePlanId, rate.unitTypeId)}
            />
          )
        })}
      </RatePlansWrapper>
    )
  }
}

RatePlans.propTypes = {
  rates: PropTypes.array,
  addItemToBasket: PropTypes.func,
  removeItemFromBasket: PropTypes.func,
  wrapperId: PropTypes.string,
  unitTypeCurrency: PropTypes.string,
  arrivalDate: PropTypes.number,
  adults: PropTypes.number,
  childrenAges: PropTypes.array,
  onOccupancyChange: PropTypes.func,
  unitTypeOccupancy: PropTypes.object,
  basket: PropTypes.array,
  unitTypes: PropTypes.array,
  openPackageDealModal: PropTypes.func
}

export default RatePlans
